const allSkills = [
  {
    logo: "/images/html-1.svg",
    skill: "HTML",
    desc: "Markup Language",
    type: "frontend",
  },
  {
    logo: "/images/css-3.svg",
    skill: "CSS",
    desc: "Style Sheet Language",
    type: "frontend",
  },
  {
    logo: "/images/tailwind-css-2.svg",
    skill: "Tailwind",
    desc: "CSS Utility",
    type: "frontend",
  },
  {
    logo: "/images/bootstrap-4.svg",
    skill: "Bootstrap",
    desc: "UI Framework",
    type: "frontend",
  },
  {
    logo: "/images/php.svg",
    skill: "PHP",
    desc: "Programming Language",
    type: "backend",
  },
  {
    logo: "/images/python-5.svg",
    skill: "Python",
    desc: "Programming Language",
    type: "backend",
  },
  {
    logo: "/images/javascript-1.svg",
    skill: "JavaScript",
    desc: "Programming Language",
    type: "frontend",
  },
  {
    logo: "/images/typescript.svg",
    skill: "TypeScript",
    desc: "Typed JavaScript",
    type: "frontend",
  },
  {
    logo: "/images/react-2.svg",
    skill: "React",
    desc: "JavaScript Library",
    type: "frontend",
  },
  {
    logo: "/images/nextjs.svg",
    skill: "Next.js",
    desc: "React Framework",
    type: "frontend",
  },
  {
    logo: "/images/redux.svg",
    skill: "Redux",
    desc: "State Management",
    type: "middleware",
  },
  {
    logo: "/images/nodejs-3.svg",
    skill: "Node.js",
    desc: "JavaScript Runtime",
    type: "backend",
  },
  {
    logo: "/images/express-109.svg",
    skill: "Express.js",
    desc: "Web Framework",
    type: "middleware",
  },
  {
    logo: "/images/mongodb-icon-1.svg",
    skill: "MongoDB",
    desc: "Database",
    type: "backend",
  },
  {
    logo: "/images/linux-tux-1.svg",
    skill: "Linux",
    desc: "Operating System",
    type: "other",
  },
  {
    logo: "/images/git-icon.svg",
    skill: "Git",
    desc: "Version Control",
    type: "other",
  },
  {
    logo: "/images/github-icon-2.svg",
    skill: "GitHub",
    desc: "Version Control Hosting",
    type: "other",
  },
  {
    logo: "/images/postman.svg",
    skill: "Postman",
    desc: "API Testing",
    type: "other",
  },
  {
    logo: "/images/figma-icon.svg",
    skill: "Figma",
    desc: "Design Tool",
    type: "other",
  },
  {
    logo: "/images/framer-motion.svg",
    skill: "Framer Motion",
    desc: "Animation Library",
    type: "frontend",
  },
  {
    logo: "/images/drupal.svg",
    skill: "Drupal",
    desc: "Flexible CMS",
    type: "other",
  },
  {
    logo: "/images/firebase.svg",
    skill: "Firebase",
    desc: "Backend Cloud Service",
    type: "backend",
  },
];

export default allSkills;
