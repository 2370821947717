import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import "./Projects.css";
import { FaGithub } from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";

const Projects = () => {
  const data = [
    {
      class: "project-one",
      grid: "project-card grid-1",
      card_content: "projects-card-content",
      title: "Paragon",
      link_icon: <FiExternalLink />,
      link: "https://pacltd.co.uk/",
      desc: "Developed a sleek and responsive landing page for a construction company for efficient client management and enhanced user engagement.",
      tools: [
        { title: "Reactjs,", icon: "/images/react-2.svg" },
        { title: "Tailwind,", icon: "/images/tailwind-css-2.svg" },
        { title: "Hubspot,", icon: "/images/hubspot.svg" },
        { title: "IONOS", icon: "/images/ionos.svg" },
      ],
    },
    {
      class: "project-two",
      grid: "project-card grid-2",
      card_content: "projects-card-content",
      title: "WorkFlow Genie",
      link_icon: <FaGithub />,
      link: "https://github.com/Fiza-04/WorkFlow-Genie",
      desc: "An efficient task management tool providing a seamless user experience to organize, track, and optimize your workflow effortlessly",
      tools: [
        { title: "Reactjs,", icon: "/images/react-2.svg" },
        { title: "Tailwind,", icon: "/images/tailwind-css-2.svg" },
        { title: "Nodejs,", icon: "/images/nodejs-3.svg" },
        { title: "Expressjs,", icon: "/images/express.svg" },
        { title: "MongoDB,", icon: "images/mongodb-icon-1.svg" },
        { title: "Postman", icon: "images/postman.svg" },
      ],
    },
    {
      class: "project-three",
      grid: "project-card grid-3",
      card_content: "projects-card-content",
      title: "Lang Shift",
      link_icon: <FaGithub />,
      link_icon2: <FiExternalLink />,
      link: "https://github.com/Fiza-04/langshift-ai4o",
      link2: "https://langshift-ai4o.vercel.app/",
      desc: "An AI-powered translator using OpenAI's GPT-4o model, offering seamless and accurate translations in a user-friendly interface.",
      tools: [
        { title: "Next,", icon: "/images/nextjs-13.svg" },
        { title: "TypeScipt", icon: "/images/typescript.svg" },
        { title: "Tailwind CSS", icon: "/images/tailwind-css-2.svg" },
        { title: "OpenAI", icon: "/images/openai.svg" },
      ],
    },
    {
      class: "project-four",
      grid: "project-card project-card-small grid-4",
      card_content: "small-card-content",
      desc_style: "small-card-desc",
      satck_style: "small-project-stack",
      title: "SnapShot",
      link_icon: <FaGithub />,
      link: "https://github.com/Fiza-04/SnapShot",
      desc: "Snapshot is a dynamic social media app with secure, scalable backend services, allowing users to seamlessly share and connect in real-time.",
      tools: [
        { title: "Reactjs,", icon: "/images/react-2.svg" },
        { title: "React Query,", icon: "/images/react-query.svg" },
        { title: "Shadcn,", icon: "/images/shadcnui.svg" },
        { title: "Tailwind,", icon: "/images/tailwind-css-2.svg" },
        { title: "Appwrite", icon: "/images/appwrite.svg" },
      ],
    },
    {
      class: "project-five",
      grid: "project-card project-card-small grid-5",
      card_content: "small-card-content",
      desc_style: "small-card-desc",
      satck_style: "small-project-stack",
      title: "LinkedIn UI Clone",
      link_icon: <FaGithub />,
      link: "https://github.com/Fiza-04/linkedin-clone",
      desc: "A web application that faithfully replicates the LinkedIn interface, showcasing modern design principles and advanced frontend development skills.",
      tools: [
        { title: "Reactjs,", icon: "/images/react-2.svg" },
        { title: "Redux,", icon: "/images/redux.svg" },
        { title: "Tailwind,", icon: "/images/tailwind-css-2.svg" },
        { title: "Ant Design,", icon: "/images/antdesign.svg" },
        { title: "Firebase", icon: "/images/firebase.svg" },
      ],
    },
  ];
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      id="project"
      className="projects-container text-white"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      <h1 className="projects-header">My Work</h1>
      <motion.div
        className="projects-cards-grid-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{
          opacity: isInView ? 1 : 0,
          y: isInView ? 0 : 50,
        }}
        transition={{
          duration: 0.6,
          ease: "easeOut",
          delayChildren: 0.3,
          staggerChildren: 0.2,
        }}
      >
        {data.map((project, index) => (
          <motion.div
            key={index}
            className={`${project.grid}`}
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className={`${project.class}`}></div>
            <div className={`${project.card_content}`}>
              <div className="project-title-icon-wrapper">
                <p className="project-title flex flex-row space-x-2">
                  {project.title}
                </p>
                <a
                  target="_blank"
                  href={project.link}
                  rel="noopener noreferrer"
                >
                  {project.link_icon}
                </a>
                {project.link2 ? (
                  <a
                    target="_blank"
                    href={project.link2}
                    rel="noopener noreferrer"
                  >
                    {project.link_icon2}
                  </a>
                ) : (
                  ""
                )}
              </div>
              <p
                className={`${
                  project.desc_style ? "small-card-desc" : "project-desc"
                }`}
              >
                {project.desc}
              </p>
              <div
                className={`project-stack ${
                  project.satck_style ? "small-project-stack" : ""
                }`}
              >
                {project.tools.map((tool, toolIndex) => (
                  <div key={toolIndex} className="project-stack-items">
                    <img src={tool.icon} alt="icon" />
                    <p>{tool.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Projects;
